import React, { useEffect } from 'react';
import { H2, Paragraph } from '@beelineloans/cx-library/dist/components/typography';
import { buildPOSURL } from '@beelineloans/cx-library/dist/utils/helpers';
import SEO from '@beelineloans/cx-library/dist/components/SEO';
import { useSessionState } from './state/StateProvider';
import { URLS, ACTIONS } from '../../const';
import Banner from '../../images/banner.svg';

const BestPart = () => {
  const [{ profile }, dispatch] = useSessionState();

  useEffect(() => {
    dispatch({
      type: ACTIONS.PAGE,
      data: {
        image: Banner,
        nextUrl: `${buildPOSURL(URLS.PROFILE_STARTER)}/${profile.profileId}`,
        nextText: "Let's get started!"
      }
    });
  }, []);

  return (
    <>
      <SEO
        path={URLS.BEST_PART}
        title="And the best part?"
      />
      <H2>And the best part?</H2>
      <Paragraph>        
        Do the entire thing in 15 minutes from your sofa, or break it up over a couple of sessions — and pick up right where you left off each time.
      </Paragraph>
    </>
  );
};

BestPart.propTypes = {
};

BestPart.defaultProps = {

};

export default BestPart;