import { ACTIONS, CONST } from '../../../const';
import { setToSessionStorage } from './browserStorage';

const stateReducer = (state, action) => {
  let newState;

  switch (action.type) {
    case ACTIONS.ALL:
      newState = {
        ...state,
        profile: action.profile,
        borrower: action.borrower,
        loading: action.loading
      };
      break;
    case ACTIONS.PAGE:
      newState = {
        ...state,
        page: action.data
      };
      break;

    case ACTIONS.STORE_PROFILE:
      newState = {
        ...state,
        profile: action.data
      };
      break;

    case ACTIONS.STORE_BORROWER:
      newState = {
        ...state,
        borrower: action.data
      };
      break;

    case ACTIONS.LOADING:
      newState = {
        ...state,
        loading: action.data
      };
      break;

    default:
      newState = state;
      break;
  }

  setToSessionStorage(CONST.PROFILE_STATE, newState);
  return newState;
};

export default stateReducer;
