import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  H2,
  H3,
  Paragraph,
} from "@beelineloans/cx-library/dist/components/typography";
import Rates from "@beelineloans/cx-library/dist/components/forms/calculator/Rates";
import { ContentWithSideImage } from "@beelineloans/cx-library/dist/components/layout/panels";
import CONST from "@beelineloans/cx-library/dist/utils/constants";
import { mapUsage, mapCreditRange, mapPropertyType } from "../../helpers";
import { URLS } from "../../const";

const Group = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Item = styled.div`
  width: 100%;

  @media only screen and (${(props) => props.theme.mediaBreakpoints.desktop}) {
    width: 30%;
  }
`;

const StepNum = styled(H3)`
  font-size: 40px;
  line-height: 48px;
  padding-bottom: 15px;

  @media only screen and (${(props) => props.theme.mediaBreakpoints.desktop}) {
    font-size: 30px;
    line-height: 36px;
    padding-bottom: 10px;
  }
`;

const NextSteps = styled(H3)`
  font-size: 30px;
  line-height: 36px;
  padding-bottom: 30px;
  padding-top: 45px;

  @media only screen and (${(props) => props.theme.mediaBreakpoints.desktop}) {
    font-size: 22px;
    line-height: 26px;
    padding-bottom: 20px;
  }
`;

const Container = styled(ContentWithSideImage)`
  .container-inner {
    padding-top: 20px;
    padding-bottom: 38px;

    @media only screen and (${(props) => props.theme.mediaBreakpoints.small}) {
      img {
        display: none;
      }
    }

    @media only screen and (${(props) =>
        props.theme.mediaBreakpoints.desktop}) {
      padding-top: 5px;
    }
  }
`;

const H3Styled = styled(H3)`
  padding-top: 34px;

  @media only screen and (${(props) => props.theme.mediaBreakpoints.desktop}) {
    padding-top: 54px;
  }
`;

const ContinueProfile = ({ borrower, profile, fieldsCompleted }) => {
  const searchValues = {
    zipCode: Number.parseInt(profile.zipCodeStr),
    creditRange: mapCreditRange(profile.creditScoreNum),
    propertyType: mapPropertyType(profile.homeTypeStr),
    occupancy: mapUsage(profile.planingStr),
    loanTerm: 30,
    loanType: "Fixed",
    purpose: profile.isRefi ? 1 : 0,
    firstTimeBuying: profile.isRefi ? "False" : "True",
    purchasePrice: Number.parseInt(
      (profile.purchaseAmountNum ?? profile.amountToSpendNum) || 0
    ),
    downPayment:
      profile.downPaymentNum || profile.downPaymentNum
        ? Number.parseInt(profile.downPaymentNum || profile.downPaymentNum || 0)
        : Number.parseInt(profile.purchaseAmountNum || 0) -
          Number.parseInt(profile.amountToSpendNum || 0),
    cashOutAmount: Number.parseInt(profile.takeOutAmountNum || 0),
    homeValue: Number.parseInt(profile.currentWorthNum || 0),
    outstandingBalance: Number.parseInt(profile.remainingAmountNum || 0),
  };

  return (
    <>
      <Container
        backgroundColor="transparent"
        imageAtTopTablet
        imageAtTopMobile
      >
        <H2>
          Hi {borrower.firstName}
          <br />
          Here is your Beeline loan quote!
        </H2>
        {profile.cityStr && (
          <H3Styled>
            We're excited about this rate quote we've prepared for your{" "}
            {profile.isRefi ? "Refinance" : "Purchase"} in {profile.cityStr},{" "}
            {profile.stateStr}
          </H3Styled>
        )}
      </Container>
      {fieldsCompleted && (
        <Rates
          defaultSearchValues={searchValues}
          isPurchase={!profile.isRefi}
          applyLink={URLS.HERES_HOW}
          version={2}
          showDetails={CONST.CALCULATOR.RATE_TYPES.BALANCED}
        >
          <NextSteps>Next steps...</NextSteps>
          <Group>
            <Item>
              <StepNum>01</StepNum>
              <Paragraph>
                <b>Skip the BS application.</b> Link directly to your exact
                income and asset info in 15 minutes for an easier, more accurate
                approval you can count on.
              </Paragraph>
            </Item>
            <Item>
              <StepNum>02</StepNum>
              <Paragraph>
                <b>No ordinary approval</b> — more like a cash offer. Because
                it’s based on your exact numbers, it’s more reliable than a
                typical pre-approval, which is more likely to change later.
              </Paragraph>
            </Item>
            <Item>
              <StepNum>03</StepNum>
              <Paragraph>
                <b>Make offers like a boss.</b> Your Purchase-Ready Approval
                letter is waiting for you in your Beeline Tracker. A dedicated
                Loan Guide supports you right up to getting those keys.
              </Paragraph>
            </Item>
          </Group>
        </Rates>
      )}
    </>
  );
};

ContinueProfile.propTypes = {
  borrower: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  fieldsCompleted: PropTypes.bool.isRequired,
};

export default ContinueProfile;
