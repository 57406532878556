/* Bunch of functions / inputs from Purchase and Refi components could be shared, duplicate code happening a bit. */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { RadioField, NumberField } from '@beelineloans/cx-library/dist/components/forms/fields';
import { Button } from '@beelineloans/cx-library/dist/components/buttons';
import { ErrorMessage } from '@beelineloans/cx-library/dist/components/typography';
import {
  CONST,
  refiWhyOptions,
  cashOutReasons,
  homeTypeOptionsRefi,
  planningOptions,
  planningOptionsNoSecondHome,
  unitOptions,
  moreThan1UnitOptions,
  payHoaFeesOptions,
  dscrLoanOptions,
  registerUnderLLCOptions,
  makeIncomeOptions,
  typeOfLoanOptions,
  typeOfLoanSelfEmployedOption,
  typeOfLoanNotSureOption
} from '../../const';
import AddressLookup from '../AddressLookup';
import { checkWarning, validateProfileRefi, handleAddressSelected, toCurrency } from '../../helpers';

const Radio = styled(RadioField)`
  input {
    max-width: 300px;
  }
`;

const Refi = ({ onSubmit, defaultValues, loading, onValidationError }) => {
  const [workingValues, setWorkingValues] = useState(defaultValues);
  const [errors, setErrors] = useState({});
  const [warnings, setWarnings] = useState({});
  const [maxCashOut, setMaxCashOut] = useState(0);

  useEffect(() => {
    setMaxCashOut(calculateMaxCashOut(workingValues.remainingAmountNum, workingValues.currentWorthNum));
  }, [workingValues]);

  const checkCompleted = () => {
    setErrors({});
    let wValues = { ...workingValues };

    // clear some values if they are not required.
    if (wValues.homeTypeStr !== CONST.MultiFamily) wValues.unitsStr = '';

    if (wValues.homeTypeStr === CONST.MultiFamily) {
      wValues.F = undefined;
    }

    if (wValues.homeTypeStr === CONST.MultiFamily && wValues.planingStr !== CONST.Investment) {
      wValues.mixedUseBool = undefined;
    }

    // if (wValues.payHoaFees === 'Yes') {
    //   wValues.homeType = CONST.PlannedUnitDevelopment;
    // }

    setWorkingValues(wValues);
    const newErrors = validateProfileRefi(wValues);

    console.info('working values', JSON.stringify(wValues));
    console.info('newErrors', JSON.stringify(newErrors));

    setErrors(newErrors);
    if (Object.entries(newErrors).length > 0) {
      onValidationError();
    }

    return {
      noErrors: Object.entries(newErrors).length === 0,
      values: wValues
    };
  };

  const handleSubmit = () => {
    if (loading) return;

    const check = checkCompleted();

    if (check.noErrors) {
      onSubmit({ hadLoanBeforeBool: CONST.Yes, ...check.values });
    }
  };

  const calculateMaxCashOut = (remainingAmountNum, currentWorthNum) => {
    const cw = Number.parseFloat(currentWorthNum);
    const ra = Number.parseFloat(remainingAmountNum ?? 0); // remainingAmountNum may be left empty
    let mco;

    if (isNaN(cw) || isNaN(ra)) mco = 0;
    else mco = cw * 0.8 - ra;
    setMaxCashOut(mco);
    return mco;
  };

  const runChecks = (newInput) => {
    const checkValues = { ...workingValues, ...newInput };

    let additionalUpdates = {};
    if (checkValues.homeTypeStr !== CONST.MultiFamily) {
      additionalUpdates = {
        ...additionalUpdates,
        mixedUseBool: undefined
      };
    }

    if (checkValues.homeTypeStr === CONST.Condo) {
      additionalUpdates = {
        ...additionalUpdates,
        payHoaFeesStr: CONST.Yes
      };
    } else if (checkValues.homeTypeStr === CONST.MultiFamily) {
      additionalUpdates = {
        ...additionalUpdates,
        payHoaFeesStr: undefined
      };
    }

    if (checkValues.homeTypeStr === CONST.MultiFamily && checkValues.planingStr === CONST.SecondHome) {
      additionalUpdates = {
        ...additionalUpdates,
        planingStr: undefined
      };
    }

    if (checkValues.planingStr !== CONST.Investment) {
      additionalUpdates = {
        ...additionalUpdates,
        dscrLoanBool: undefined,
        registerUnderLlcBool: undefined,
        mixedUseBool: undefined
      };
    }
    if (checkValues.planingStr === CONST.Investment && checkValues.dscrLoanBool !== CONST.Yes) {
      additionalUpdates = {
        ...additionalUpdates,
        registerUnderLlcBool: undefined
      };
    }

    if (checkValues.makeIncome !== 'self-employed' && checkValues.typeOfLoan !== 'conventional') {
      additionalUpdates = {
        ...additionalUpdates,
        typeOfLoan: undefined
      };
    }

    return { ...checkValues, ...additionalUpdates };
  };

  const handleInputChange = (field, type) => (event) => {
    let newValue = event.target.value;

    if (typeof newValue === 'undefined') newValue = event; // used for DropDownCustom component

    if (type === 'num' && newValue !== null) {
      const parsedNumber = Number.parseFloat(newValue);
      if (!isNaN(parsedNumber)) newValue = parsedNumber;
      else newValue = undefined;
    }

    const updatedValue = runChecks({ [field]: newValue });

    const newErrors = {
      [field]: undefined
    };

    setWarnings({});
    const newWarnings = {};

    if (field === 'creditScoreNum') {
      checkWarning(updatedValue, newWarnings, 'creditScoreNum', 'a credit score', null, true, 0, 999, false);
    }

    // if (field === 'remainingAmountNum') calculateMaxCashOut(newValue, workingValues.currentWorth)

    // if (field === 'currentWorth') calculateMaxCashOut(workingValues.remainingAmountNum, newValue)

    setWorkingValues((prev) => ({ ...prev, ...updatedValue }));
    setErrors((prev) => ({ ...prev, ...newErrors }));
    setWarnings((prev) => ({ ...prev, ...newWarnings }));
  };

  const addressSelected = (address) => {
    handleAddressSelected(address, setWorkingValues);
  };

  const productTypeOptions = [...typeOfLoanOptions];
  if (workingValues.makeIncome === 'self-employed') {
    // && workingValues.planingStr !== 'Investment') {
    productTypeOptions.push(typeOfLoanSelfEmployedOption);
    productTypeOptions.push(typeOfLoanNotSureOption);
  }

  return (
    <>
      <AddressLookup
        address={workingValues.addressStr}
        onChange={handleInputChange('addressStr')}
        onSelect={addressSelected}
        label="So what’s the address of the property you want to refinance?"
      />
      <Radio
        id="makeIncome"
        label="What’s your employment type?"
        onInputChange={handleInputChange('makeIncome')}
        options={makeIncomeOptions}
        selectedValue={workingValues.makeIncome}
        border={false}
        error={errors.makeIncome}
      />
      <NumberField
        id="creditScoreNum"
        label="Ok, what is your credit score?"
        value={workingValues.creditScoreNum || 0}
        onInputChange={handleInputChange('creditScoreNum', 'num')}
        border={false}
        rounded
        placeholder=""
        error={errors.creditScoreNum || warnings.creditScoreNum}
      />
      <NumberField
        id="remainingAmountNum"
        label="How much is left on your home loan?"
        value={workingValues.remainingAmountNum || 0}
        onInputChange={handleInputChange('remainingAmountNum', 'num')}
        border={false}
        rounded
        prefix="$"
        formatValue
        placeholder="0"
        error={errors.remainingAmountNum}
      />
      <NumberField
        id="currentWorthNum"
        label="What’s your home currently worth? Feel free to guesstimate."
        value={workingValues.currentWorthNum || 0}
        onInputChange={handleInputChange('currentWorthNum', 'num')}
        border={false}
        rounded
        prefix="$"
        formatValue
        placeholder="0"
        error={errors.currentWorthNum}
      />
      <Radio
        id="whyRefiStr"
        label="So, tell us why you want to refinance."
        onInputChange={handleInputChange('whyRefiStr')}
        options={refiWhyOptions}
        selectedValue={workingValues.whyRefiStr}
        border={false}
        error={errors.whyRefiStr}
      />
      {workingValues.whyRefiStr === CONST.CashOut && (
        <>
          <NumberField
            id="takeOutAmountNum"
            label={`How much do you want to take out? ${maxCashOut > 0 ? `Looks like you can get up to ${toCurrency(maxCashOut)}` : ''}`}
            value={workingValues.takeOutAmountNum || 0}
            onInputChange={handleInputChange('takeOutAmountNum', 'num')}
            border={false}
            rounded
            prefix="$"
            formatValue
            placeholder="0"
            error={errors.takeOutAmountNum}
          />
          {workingValues.takeOutAmountNum > maxCashOut ? (
            <>
              <ErrorMessage>
                Aiming high, huh? That’s cool, just a heads-up though — typically you can only get up to 80% of your property’s value including your outstanding loan.
              </ErrorMessage>
              &nbsp;
              <br />
              <br />
            </>
          ) : null}
          <Radio
            id="cashOutReasonStr"
            label="What do you plan to use this cash for?"
            options={cashOutReasons}
            onInputChange={handleInputChange('cashOutReasonStr')}
            selectedValue={workingValues.cashOutReasonStr}
            border={false}
            error={errors.cashOutReasonStr}
          />
        </>
      )}
      <Radio
        id="homeTypeStr"
        label="What type of property is it?"
        onInputChange={handleInputChange('homeTypeStr')}
        options={homeTypeOptionsRefi}
        selectedValue={workingValues.homeTypeStr}
        border={false}
        error={errors.homeTypeStr}
      />
      {workingValues.homeTypeStr === CONST.MultiFamily && (
        <Radio
          id="unitsStr"
          label="How many units is this property?"
          options={workingValues.homeTypeStr === CONST.MultiFamily ? moreThan1UnitOptions : unitOptions}
          onInputChange={handleInputChange('unitsStr')}
          selectedValue={workingValues.unitsStr}
          border={false}
          error={errors.unitsStr}
        />
      )}

      {(workingValues.homeTypeStr === CONST.SingleFamily || workingValues.homeTypeStr === CONST.NotSure) && (
        <Radio
          id="payHoaFeesStr"
          label="Do you pay home owners association (HOA) fees or neighborhood dues for this property?"
          options={payHoaFeesOptions}
          onInputChange={handleInputChange('payHoaFeesStr')}
          selectedValue={workingValues.payHoaFeesStr}
          border={false}
          error={errors.payHoaFeesStr}
        />
      )}
      <Radio
        id="planingStr"
        label="And are you planning to live in this home?"
        options={workingValues.homeTypeStr === CONST.MultiFamily ? planningOptionsNoSecondHome : planningOptions}
        onInputChange={handleInputChange('planingStr')}
        selectedValue={workingValues.planingStr}
        border={false}
        error={errors.planingStr}
      />
      {workingValues.planingStr === CONST.Investment && (
        <Radio
          id="dscrLoanBool"
          label="Are you applying using the rental property’s income (DSCR loan) or your personal income?"
          options={dscrLoanOptions}
          onInputChange={handleInputChange('dscrLoanBool')}
          selectedValue={workingValues.dscrLoanBool}
          border={false}
          error={errors.dscrLoanBool}
        />
      )}
      {workingValues.planingStr === CONST.Investment && workingValues.dscrLoanBool === CONST.Yes && (
        <Radio
          id="registerUnderLlcBool"
          label="Are you buying in your name or in business entity?"
          options={registerUnderLLCOptions}
          onInputChange={handleInputChange('registerUnderLlcBool')}
          selectedValue={workingValues.registerUnderLlcBool}
          border={false}
          error={errors.registerUnderLlcBool}
        />
      )}

      {workingValues.dscrLoanBool !== CONST.Yes && (
        <Radio
          id="typeOfLoan"
          label="What type of loan are you looking for?"
          options={productTypeOptions}
          onInputChange={handleInputChange('typeOfLoan')}
          selectedValue={workingValues.typeOfLoan}
          border={false}
          error={errors.typeOfLoan}
        />
      )}

      {(workingValues.planingStr === CONST.Investment || workingValues.homeTypeStr === CONST.MultiFamily) && (
        <NumberField
          id="estimatedRentalNum"
          label={
            workingValues.homeTypeStr === CONST.MultiFamily && workingValues.planingStr !== CONST.Investment
              ? 'So, how much rent do you get each month for the other units in your multi-family property?'
              : 'How much rent are you expecting to get each month?'
          }
          value={workingValues.estimatedRentalNum || 0}
          onInputChange={handleInputChange('estimatedRentalNum', 'num')}
          border={false}
          rounded
          prefix="$"
          formatValue
          placeholder="0"
          error={errors.estimatedRentalNum}
        />
      )}
      {workingValues.homeTypeStr === CONST.MultiFamily && workingValues.planingStr === CONST.Investment && (
        <Radio
          id="mixedUseBool"
          label="Is this property mixed use? (residential with at least one commercial unit)."
          options={payHoaFeesOptions}
          onInputChange={handleInputChange('mixedUseBool')}
          selectedValue={workingValues.mixedUseBool}
          border={false}
          error={errors.mixedUseBool}
        />
      )}
      <Button onClick={handleSubmit} loading={loading}>
        Save
      </Button>
    </>
  );
};

Refi.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onValidationError: PropTypes.func,
  defaultValues: PropTypes.object,
  loading: PropTypes.bool
};

Refi.defaultProps = {
  defaultValues: {
    buyingOrRefinance: 'refinance',
    address: ''
  },
  onValidationError: () => {},
  loading: false
};

export default Refi;
