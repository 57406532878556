const CONST = {
  MultiFamily: "MultiFamily",
  SingleFamily: "SingleFamily",
  NotSure: "NotSure",
  PlannedUnitDevelopment: "PlannedUnitDevelopment",
  Condo: "Condo",
  CashOut: "CashOut",
  Investment: "Investment",
  SecondHome: "Second Home",
  Yes: "Yes",
  No: "No",
  PROFILE_STATE: "profile-redirection",
};

const URLS = {
  BEST_PART: "/continue/best-part",
  HERES_HOW: "/continue/heres-how",
  CONTINUE: "/continue",
  LEAD: "/lead",
  PROFILE_STARTER: "/profilestart",
};

const ACTIONS = {
  ALL: "ALL",
  STORE_PROFILE: "STORE_PROFILE",
  STORE_BORROWER: "STORE_BORROWER",
  LOADING: "LOADING",
  PAGE: "PAGE",
};

const creditRangeOptions = [
  { val: 760, text: "Excellent (> 760)" },
  { val: 740, text: "Very good (740 - 759)" },
  { val: 720, text: "Good (720 - 739)" },
  { val: 700, text: "Decent (700 - 719)" },
  { val: 680, text: "Average (680 - 699)" },
  { val: 660, text: "Fair (660 - 679)" },
  { val: 620, text: "Low (620 - 659)" },
  { val: 580, text: "Very Low (580 - 619)" },
  { val: 570, text: "Poor (< 580)" },
];

const refiWhyOptions = [
  { val: "ReduceTerm", text: "Reduce loan term" },
  { val: CONST.CashOut, text: "Take cash out" },
  { val: "LowerRepayments", text: "Lower payment" },
];

const cashOutReasons = [
  { val: "DebtConsolidation", text: "Debt consolidation" },
  { val: "HomeImprovement", text: "Home improvements" },
  { val: "Other", text: "Other" },
];

const homeTypeOptionsPurchase = [
  { val: "SingleFamily", text: "Single-family" },
  { val: CONST.MultiFamily, text: "Multi-family" },
  { val: CONST.Condo, text: "Condo" },
  // { val: CONST.PlannedUnitDevelopment, text: 'Neighborhood Association/HOA' },
  { val: "NotSure", text: "Not sure" },
];

const homeTypeOptionsRefi = [
  { val: "SingleFamily", text: "Single-family" },
  { val: CONST.MultiFamily, text: "Multi-family" },
  { val: CONST.Condo, text: "Condo" },
  { val: "NotSure", text: "Not sure" },
];

const planningOptions = [
  { val: "Primary", text: "Yes" },
  { val: CONST.Investment, text: "No, it's an investment" },
  { val: "Second Home", text: "No, it's my second home" },
];
const planningOptionsNoSecondHome = [
  { val: "Primary", text: "Yes" },
  { val: CONST.Investment, text: "No, it's an investment" },
];

const unitOptions = [
  { val: "1", text: "1 Unit" },
  { val: "2", text: "2 Units" },
  { val: "3", text: "3 Units" },
  { val: "4", text: "4 Units" },
  { val: "5+", text: "5+ Units" },
];

const moreThan1UnitOptions = [
  { val: "2", text: "2 Units" },
  { val: "3", text: "3 Units" },
  { val: "4", text: "4 Units" },
  { val: "5+", text: "5+ Units" },
];

const hadLoanOptions = [
  { val: "Yes", text: "Yes" },
  { val: "No", text: "No this first time" },
];

const PSOptions = [
  { val: "Yes", text: "Sure do" },
  { val: "No", text: "No, not yet" },
];

const makeIncomeOptions = [
  { val: "employed", text: "Employed" },
  { val: "self-employed", text: "Self Employed" },
  { val: "retired", text: "Retired" },
  { val: "not-employed", text: "Other" },
];

const payHoaFeesOptions = [
  { val: "Yes", text: "Yes" },
  { val: "No", text: "No" },
];

const dscrLoanOptions = [
  { val: "No", text: "My income" },
  { val: "Yes", text: "Rental income" },
];

const typeOfLoanOptions = [{ val: "conventional", text: "Conventional" }];
const typeOfLoanNotSureOption = {
  val: "exploreBoth",
  text: "I'm not sure. Let's explore both",
};
const typeOfLoanSelfEmployedOption = {
  val: "bank-statement",
  text: "Bank statement loan",
};

const registerUnderLLCOptions = [
  { val: "No", text: "My name" },
  { val: "Yes", text: "Business entity" },
];

const howSoonOptions = [
  { val: "now", text: "Right away" },
  { val: "1-3m", text: "1 - 3 months" },
  { val: "3-6m", text: "3 - 6 months" },
  { val: "6+m", text: "6 months or more" },
  { val: "unknown", text: "Not sure" },
];

export {
  CONST,
  refiWhyOptions,
  cashOutReasons,
  homeTypeOptionsPurchase,
  homeTypeOptionsRefi,
  planningOptions,
  planningOptionsNoSecondHome,
  unitOptions,
  moreThan1UnitOptions,
  hadLoanOptions,
  PSOptions,
  howSoonOptions,
  creditRangeOptions,
  payHoaFeesOptions,
  dscrLoanOptions,
  registerUnderLLCOptions,
  makeIncomeOptions,
  typeOfLoanOptions,
  typeOfLoanNotSureOption,
  typeOfLoanSelfEmployedOption,
  ACTIONS,
  URLS,
};
