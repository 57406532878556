/* Bunch of functions / inputs from Purchase and Refi components could be shared, duplicate code happening a bit. */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { RadioField, NumberField } from '@beelineloans/cx-library/dist/components/forms/fields';
import { Button } from '@beelineloans/cx-library/dist/components/buttons';
import {
  CONST,
  hadLoanOptions,
  PSOptions,
  homeTypeOptionsPurchase,
  planningOptions,
  planningOptionsNoSecondHome,
  unitOptions,
  moreThan1UnitOptions,
  howSoonOptions,
  payHoaFeesOptions,
  dscrLoanOptions,
  registerUnderLLCOptions,
  makeIncomeOptions,
  typeOfLoanOptions,
  typeOfLoanSelfEmployedOption,
  typeOfLoanNotSureOption
} from '../../const';
import AddressLookup from '../AddressLookup';
import { checkWarning, validateProfilePurchase, handleAddressSelected } from '../../helpers';

const Radio = styled(RadioField)`
  input {
    max-width: 300px;
  }
`;

const Purchase = ({ onSubmit, defaultValues, loading, onValidationError }) => {
  const [workingValues, setWorkingValues] = useState(defaultValues);
  const [errors, setErrors] = useState({});
  const [warnings, setWarnings] = useState({});

  const checkCompleted = () => {
    setErrors({});
    let wValues = { ...workingValues };

    if (wValues.homeTypeStr !== CONST.MultiFamily) wValues.unitsStr = '';

    if (wValues.homeTypeStr === CONST.MultiFamily) {
      wValues.payHoaFeesStr = undefined;
      wValues.hoaFeesAmountNum = undefined;
    }

    if (wValues.homeTypeStr === CONST.MultiFamily && wValues.planingStr !== CONST.Investment) {
      wValues.mixedUse = undefined;
    }

    if (wValues.payHoaFeesStr !== CONST.Yes) wValues.hoaFeesAmountNum = undefined;

    setWorkingValues(wValues);
    const newErrors = validateProfilePurchase(wValues);

    console.info('working values', JSON.stringify(wValues));
    console.info('newErrors', JSON.stringify(newErrors));
    setErrors(newErrors);

    if (Object.entries(newErrors).length > 0) {
      onValidationError();
    }

    return Object.entries(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (loading) return;

    if (checkCompleted()) {
      onSubmit(workingValues);
    }
  };

  const runChecks = (newInput) => {
    const checkValues = { ...workingValues, ...newInput };
    let additionalUpdates = {};
    if (checkValues.homeTypeStr !== CONST.MultiFamily) {
      additionalUpdates = {
        ...additionalUpdates,
        mixedUseBool: undefined
      };
    }

    if (checkValues.hasSignedPurchaseBool !== CONST.Yes) {
      additionalUpdates = {
        ...additionalUpdates,
        payHoaFeesStr: undefined,
        hoaFeesAmountNum: undefined
      };
    } else {
      if (checkValues.homeTypeStr === CONST.Condo) {
        additionalUpdates = {
          ...additionalUpdates,
          payHoaFeesStr: CONST.Yes
        };
      } else if (checkValues.homeTypeStr === CONST.MultiFamily) {
        additionalUpdates = {
          ...additionalUpdates,
          payHoaFeesStr: undefined,
          hoaFeesAmountNum: undefined
        };
      }
    }

    if (checkValues.homeTypeStr === CONST.MultiFamily && checkValues.planingStr === CONST.SecondHome) {
      additionalUpdates = {
        ...additionalUpdates,
        planingStr: undefined
      };
    }

    if (checkValues.planingStr !== CONST.Investment) {
      additionalUpdates = {
        ...additionalUpdates,
        dscrLoanBool: undefined,
        registerUnderLlcBool: undefined
      };
    }
    if (checkValues.planingStr === CONST.Investment && checkValues.dscrLoanBool !== CONST.Yes) {
      additionalUpdates = {
        ...additionalUpdates,
        registerUnderLlcBool: undefined
      };
    }

    if (checkValues.makeIncome !== 'self-employed' && checkValues.typeOfLoan !== 'conventional') {
      additionalUpdates = {
        ...additionalUpdates,
        typeOfLoan: undefined
      };
    }

    return { ...checkValues, ...additionalUpdates };
  };

  const handleInputChange = (field, type) => (event) => {
    let newValue = event.target.value;

    if (typeof newValue === 'undefined') newValue = event; // used for DropDownCustom component

    if (type === 'num' && newValue !== null) {
      const parsedNumber = Number.parseFloat(newValue);
      if (!isNaN(parsedNumber)) newValue = parsedNumber;
      else newValue = undefined;
    }

    const updatedValue = runChecks({ [field]: newValue });

    const newErrors = {
      [field]: undefined
    };

    setWarnings({});
    const newWarnings = {};
    if (field === 'purchaseAmountNum' || field === 'amountToSpendNum') {
      if (workingValues.hasSignedPurchaseBool === CONST.Yes) {
        checkWarning(updatedValue, newWarnings, 'purchaseAmountNum', 'the purchase amount', null, true, 100000, 1000000);
      }
      if (workingValues.hasSignedPurchaseBool !== CONST.Yes) {
        checkWarning(updatedValue, newWarnings, 'amountToSpendNum', 'the amount to spend', null, true, 100000, 1000000);
      }
    }

    if (field === 'creditScoreNum') {
      checkWarning(updatedValue, newWarnings, 'creditScoreNum', 'a credit score', null, true, 0, 999, false);
    }

    setWorkingValues((prev) => ({ ...prev, ...updatedValue }));
    setErrors((prev) => ({ ...prev, ...newErrors }));
    setWarnings((prev) => ({ ...prev, ...newWarnings }));
  };

  const addressSelected = (address) => {
    handleAddressSelected(address, setWorkingValues);
  };

  const productTypeOptions = [...typeOfLoanOptions];
  if (
    workingValues.makeIncome === 'self-employed' //&&
    //workingValues.planingStr !== "Investment"
  ) {
    productTypeOptions.push(typeOfLoanSelfEmployedOption);
    productTypeOptions.push(typeOfLoanNotSureOption);
  }

  return (
    <>
      <Radio
        id="hadLoanBeforeBool"
        label="Have you had a home loan before?"
        onInputChange={handleInputChange('hadLoanBeforeBool')}
        options={hadLoanOptions}
        selectedValue={workingValues.hadLoanBeforeBool}
        border={false}
        error={errors.hadLoanBeforeBool}
      />
      <Radio
        id="hasSignedPurchaseBool"
        label="Okay, and do you have a signed purchase and sale agreement?"
        onInputChange={handleInputChange('hasSignedPurchaseBool')}
        options={PSOptions}
        selectedValue={workingValues.hasSignedPurchaseBool}
        border={false}
        error={errors.hasSignedPurchaseBool}
      />
      <Radio
        id="makeIncome"
        label="What’s your employment type?"
        onInputChange={handleInputChange('makeIncome')}
        options={makeIncomeOptions}
        selectedValue={workingValues.makeIncome}
        border={false}
        error={errors.makeIncome}
      />
      <AddressLookup
        address={workingValues.addressStr}
        placeType={workingValues.hasSignedPurchaseBool === CONST.Yes ? 'address' : '(regions)'}
        onChange={handleInputChange('addressStr')}
        onSelect={addressSelected}
        error={errors.addressStr}
        label={
          workingValues.hasSignedPurchaseBool === CONST.Yes
            ? 'What’s the address of the property you want to buy?'
            : 'Nice. What area are you looking to buy (Please include city, county, state & zip code)?'
        }
      />
      <NumberField
        id="creditScoreNum"
        label="Ok, what is your credit score?"
        value={workingValues.creditScoreNum || 0}
        onInputChange={handleInputChange('creditScoreNum', 'num')}
        border={false}
        rounded
        placeholder=""
        error={errors.creditScoreNum || warnings.creditScoreNum}
      />
      {workingValues.hasSignedPurchaseBool === CONST.Yes && (
        <NumberField
          id="purchaseAmountNum"
          label="Sweet! What's the agreed purchase price?"
          value={workingValues.purchaseAmountNum || 0}
          onInputChange={handleInputChange('purchaseAmountNum', 'num')}
          border={false}
          rounded
          prefix="$"
          formatValue
          placeholder="0"
          error={errors.purchaseAmountNum || warnings.purchaseAmountNum}
        />
      )}
      {workingValues.hasSignedPurchaseBool !== CONST.Yes && (
        <>
          <Radio
            id="howSoonToBuyStr"
            label="How soon are you planning to buy?"
            onInputChange={handleInputChange('howSoonToBuyStr')}
            options={howSoonOptions}
            selectedValue={workingValues.howSoonToBuyStr}
            border={false}
            error={errors.howSoonToBuyStr}
          />
          <NumberField
            id="amountToSpendNum"
            label="Roughly, what are you thinking price-wise?"
            value={workingValues.amountToSpendNum || 0}
            onInputChange={handleInputChange('amountToSpendNum', 'num')}
            border={false}
            rounded
            prefix="$"
            formatValue
            placeholder="0"
            error={errors.amountToSpendNum || warnings.amountToSpendNum}
          />
        </>
      )}
      <NumberField
        id="downPaymentNum"
        label="How much is your down payment?"
        value={workingValues.downPaymentNum || 0}
        onInputChange={handleInputChange('downPaymentNum', 'num')}
        border={false}
        rounded
        prefix="$"
        formatValue
        placeholder="0"
        error={errors.downPaymentNum || warnings.downPaymentNum}
      />
      <Radio
        id="homeTypeStr"
        label="What type of property is it?"
        onInputChange={handleInputChange('homeTypeStr')}
        options={homeTypeOptionsPurchase}
        selectedValue={workingValues.homeTypeStr}
        border={false}
        error={errors.homeTypeStr}
      />
      {workingValues.homeTypeStr === CONST.MultiFamily && (
        <Radio
          id="unitsStr"
          label="How many units is this property?"
          options={workingValues.homeTypeStr === CONST.MultiFamily ? moreThan1UnitOptions : unitOptions}
          onInputChange={handleInputChange('unitsStr')}
          selectedValue={workingValues.unitsStr}
          border={false}
          error={errors.unitsStr}
        />
      )}
      {workingValues.hasSignedPurchaseBool === CONST.Yes && (workingValues.homeTypeStr === CONST.SingleFamily || workingValues.homeTypeStr === CONST.NotSure) && (
        <Radio
          id="payHoaFeesStr"
          label="Do you pay home owners association (HOA) fees or neighborhood dues for this property?"
          options={payHoaFeesOptions}
          onInputChange={handleInputChange('payHoaFeesStr')}
          selectedValue={workingValues.payHoaFeesStr}
          border={false}
          error={errors.payHoaFeesStr}
        />
      )}
      {workingValues.payHoaFeesStr === CONST.Yes && (
        <NumberField
          id="hoaFeesAmountNum"
          label="Okay, how much will you pay in HOA fees each month?"
          value={workingValues.hoaFeesAmountNum || 0}
          onInputChange={handleInputChange('hoaFeesAmountNum', 'num')}
          border={false}
          rounded
          prefix="$"
          formatValue
          placeholder="0"
          error={errors.hoaFeesAmountNum}
        />
      )}
      <Radio
        id="planingStr"
        label="And are you planning to live in this home?"
        options={workingValues.homeTypeStr === CONST.MultiFamily ? planningOptionsNoSecondHome : planningOptions}
        onInputChange={handleInputChange('planingStr')}
        selectedValue={workingValues.planingStr}
        border={false}
        error={errors.planingStr}
      />

      {workingValues.planingStr === CONST.Investment && (
        <Radio
          id="dscrLoanBool"
          label="Are you applying using the rental property’s income (DSCR loan) or your personal income?"
          options={dscrLoanOptions}
          onInputChange={handleInputChange('dscrLoanBool')}
          selectedValue={workingValues.dscrLoanBool}
          border={false}
          error={errors.dscrLoanBool}
        />
      )}
      {workingValues.planingStr === CONST.Investment && workingValues.dscrLoanBool === CONST.Yes && (
        <Radio
          id="registerUnderLlcBool"
          label="Are you buying in your name or in business entity?"
          options={registerUnderLLCOptions}
          onInputChange={handleInputChange('registerUnderLlcBool')}
          selectedValue={workingValues.registerUnderLlcBool}
          border={false}
          error={errors.registerUnderLlcBool}
        />
      )}

      {workingValues.dscrLoanBool !== CONST.Yes && (
        <Radio
          id="typeOfLoan"
          label="What type of loan are you looking for?"
          options={productTypeOptions}
          onInputChange={handleInputChange('typeOfLoan')}
          selectedValue={workingValues.typeOfLoan}
          border={false}
          error={errors.typeOfLoan}
        />
      )}
      {(workingValues.planingStr === CONST.Investment || workingValues.homeTypeStr === CONST.MultiFamily) && (
        <NumberField
          id="estimatedRentalNum"
          label={
            workingValues.homeTypeStr === CONST.MultiFamily && workingValues.planingStr !== CONST.Investment
              ? 'So, how much rent do you get each month for the other units in your multi-family property?'
              : 'How much rent are you expecting to get each month?'
          }
          value={workingValues.estimatedRentalNum || 0}
          onInputChange={handleInputChange('estimatedRentalNum', 'num')}
          border={false}
          rounded
          prefix="$"
          formatValue
          placeholder="0"
          error={errors.estimatedRentalNum}
        />
      )}
      {workingValues.homeTypeStr === CONST.MultiFamily && workingValues.planingStr === CONST.Investment && (
        <Radio
          id="mixedUseBool"
          label="Is this property mixed use? (residential with at least one commercial unit)."
          options={payHoaFeesOptions}
          onInputChange={handleInputChange('mixedUseBool')}
          selectedValue={workingValues.mixedUseBool}
          border={false}
          error={errors.mixedUseBool}
        />
      )}
      <Button onClick={handleSubmit} loading={loading}>
        Save
      </Button>
    </>
  );
};

Purchase.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onValidationError: PropTypes.func,
  defaultValues: PropTypes.object,
  loading: PropTypes.bool
};

Purchase.defaultProps = {
  defaultValues: {
    buyingOrRefinance: 'buying',
    address: ''
  },
  onValidationError: () => {},
  loading: false
};

export default Purchase;
