import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { postTry } from "@beelineloans/cx-library/dist/utils/fetch";
import { RadioField } from "@beelineloans/cx-library/dist/components/forms/fields";
import {
  SuccessMessage,
  ErrorMessage,
} from "@beelineloans/cx-library/dist/components/typography";
// import { Button } from '@beelineloans/cx-library/dist/components/buttons';
import Purchase from "./Purchase";
import Refi from "./Refi";

const Radio = styled(RadioField)`
  input {
    max-width: 200px;
  }
`;

const Wrap = styled.div`
  padding-top: 40px;
  label {
    color: ${(props) => props.theme.colours.text.dark};
    font-size: 18px;
  }
`;

// const SendButton = styled(Button)`
//   margin-left: 20px;
// `;

const WarmUp = ({ crmId, profileId, defaultValues }) => {
  const [applicationType, setApplicationType] = useState(
    defaultValues && defaultValues.buyingOrRefinanceStr
      ? defaultValues.buyingOrRefinanceStr === "buying"
        ? "purchase"
        : "refi"
      : null
  );
  const [workingProfileId, setWorkingProfileId] = useState(profileId);
  const [submitted, setSubmitted] = useState(false);
  const [errored, setErrored] = useState(null);
  // const [sent, setSent] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [profileLink, setProfileLink] = useState(null);
  // const [sending, setSending] = useState(false);

  const applicationTypeOptions = [
    { val: "purchase", text: "Purchase" },
    { val: "refi", text: "Refinance" },
  ];

  const handleChange = (event) => {
    if (event.target.value === applicationType) return;

    setApplicationType(event.target.value);
  };

  // const scrollUp = () => {
  //   if (window) window.scrollTo({ top: 0, behavior: 'smooth' });
  // };

  // const handleSend = async () => {
  //   if (sending) return;
  //   setSending(true);
  //   setSubmitted(false);
  //   setErrored(false);
  //   let hasErrored = false;

  //   const sendResponse = await postTry(`${process.env.REACT_APP_API_ENDPOINT}send/`, {
  //     crmId,
  //     workingProfileId
  //   });

  //   if (sendResponse.error)
  //     hasErrored = true;
  //   else
  //     hasErrored = sendResponse.status !== 'SUCCESS';

  //   if (hasErrored) setErrored('An error has occured');
  //   else setSent(true);

  //   setSending(false);
  // };

  const onValidationError = () => {
    setErrored("Please fix the fields marked in red.");
  };

  const handleSubmit = async (data) => {
    if (submitting) return;
    setProfileLink(null);
    setSubmitting(true);
    // setSent(false);
    setErrored(null);

    let hasErrored = false;
    const body = {
      crmId,
      profileId: workingProfileId,
      ...data,
      buyingOrRefinanceStr: applicationType,
    };
    console.info("submit body", body);
    const submitResponse = await postTry(
      `${process.env.REACT_APP_API_ENDPOINT}submit/`,
      body
    );

    if (!submitResponse || !submitResponse.id) {
      hasErrored = true;
      console.info("submitResponse", JSON.stringify(submitResponse));
      setErrored(
        submitResponse.message ?? "Please fix the fields marked in red"
      );
    } else {
      if (!workingProfileId) {
        setWorkingProfileId(submitResponse.id);
        setProfileLink(
          `${process.env.REACT_APP_POS}/profilestart/${submitResponse.id}`
        );
      } else {
        setProfileLink(
          `${process.env.REACT_APP_POS}/profilestart/${workingProfileId}`
        );
      }
    }

    if (!hasErrored) setSubmitted(true);

    setSubmitting(false);
  };

  return (
    <Wrap>
      <Radio
        id="applicationType"
        options={applicationTypeOptions}
        onInputChange={handleChange}
        selectedValue={applicationType}
        label="Type of application"
      />
      {applicationType === "purchase" && (
        <Purchase
          onSubmit={handleSubmit}
          submitted={submitted}
          loading={submitting}
          defaultValues={defaultValues}
          onValidationError={onValidationError}
        />
      )}
      {applicationType === "refi" && (
        <Refi
          onSubmit={handleSubmit}
          submitted={submitted}
          loading={submitting}
          defaultValues={defaultValues}
          onValidationError={onValidationError}
        />
      )}
      {/* {!sent && (!!(defaultValues || {}).updatedViaForm || submitted) && (<SendButton onClick={handleSend} loading={sending}>Send Email &amp; SMS</SendButton>)} */}

      {errored && <ErrorMessage>{errored}</ErrorMessage>}
      {submitted && (
        <SuccessMessage>
          Profile has been saved:{" "}
          <a href={profileLink} target="_blank" rel="noreferrer">
            {profileLink}
          </a>
        </SuccessMessage>
      )}
      {/* {sent && (<SuccessMessage>Profile comms have been sent</SuccessMessage>)} */}
    </Wrap>
  );
};

WarmUp.propTypes = {
  crmId: PropTypes.string.isRequired,
  profileId: PropTypes.string,
  defaultValues: PropTypes.object,
};

WarmUp.defaultProps = {
  profileId: undefined,
  defaultValues: undefined,
};

export default WarmUp;
