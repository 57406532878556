import { CONST } from './const';

// checkError(values, newErrors, 'remainingAmountNum', 'the remaining amount', null, true);

const checkError = (workingValues, newErrors, field, displayName, errorField, isNum = false, isLoading = false) => {
  checkWarning(workingValues, newErrors, field, displayName, errorField, isNum, null, null, true, isLoading);
};

const toCurrency = (v) =>
  v
    ? new Intl.NumberFormat('en-AU', {
        style: 'currency',
        currency: 'AUD'
      }).format(v)
    : '';

const checkWarning = (workingValues, warnings, field, displayName, warningField, isNum = false, minNum, maxNum, valueToCurrency = true, isLoading = false) => {
  if (!isNum && !workingValues[field]) {
    warnings[warningField || field] = `Please enter ${displayName}`;
  } else if (!isNum && workingValues[field].trim() === '') {
    warnings[warningField || field] = `Please enter ${displayName}`;
  }

  if (isNum) {
    if ((isNaN(workingValues[field]) || workingValues[field] === null) && (field !== 'remainingAmountNum' || isLoading)) {
      warnings[warningField || field] = `Please enter ${displayName}`;
    } else {
      const parsedValue = parseFloat(workingValues[field]);
      if (parsedValue < 1 && (field !== 'remainingAmountNum' || isLoading)) warnings[warningField || field] = `Please enter ${displayName}`;
      if (minNum && parsedValue < minNum) warnings[warningField || field] = `${displayName} is suggested to be greater than ${valueToCurrency ? toCurrency(minNum) : minNum}`;
      if (maxNum && parsedValue > maxNum) warnings[warningField || field] = `${displayName} is suggested to be less than ${valueToCurrency ? toCurrency(maxNum) : maxNum}`;
    }
  }
};

const getAddressDetails = (address_components, searchFor, attr = 'long_name') => {
  const result = address_components.find((a) => a.types.indexOf(searchFor) > -1);
  if (!result) return undefined;
  return result[attr];
};

const validateProfileRefi = (values, isLoading = false) => {
  const newErrors = {};

  checkError(values, newErrors, 'whyRefiStr', 'a refinance type');
  checkError(values, newErrors, 'remainingAmountNum', 'the remaining amount', null, true, isLoading);

  checkError(values, newErrors, 'makeIncome', 'the employment type');
  checkError(values, newErrors, 'currentWorthNum', 'the current value', null, true);
  checkError(values, newErrors, 'homeTypeStr', 'the property type');
  checkError(values, newErrors, 'planingStr', 'if you are planning on living in the property');
  checkError(values, newErrors, 'creditScoreNum', 'a credit score', null, true);

  checkError(values, newErrors, 'addressLine', '1an address from the auto complete list', 'address');
  checkError(values, newErrors, 'cityStr', '2an address from the auto complete list', 'address');
  checkError(values, newErrors, 'stateStr', '3an address from the auto complete list', 'address');
  checkError(values, newErrors, 'zipCodeStr', '4an address from the auto complete list', 'address');

  if (values.whyRefiStr === CONST.CashOut) {
    checkError(values, newErrors, 'takeOutAmountNum', 'the amount you want to cash out', null, true);
    checkError(values, newErrors, 'cashOutReasonStr', 'the cash out reason');
  }

  if (values.planingStr === CONST.Investment) {
    checkError(values, newErrors, 'dscrLoanBool', 'if it is a DSCR loan');
  }

  if (values.planingStr === CONST.Investment && values.dscrLoanBool === CONST.Yes) {
    checkError(values, newErrors, 'registerUnderLlcBool', 'if you are buying in your name');
  }

  if (values.dscrLoanBool !== CONST.Yes) {
    checkError(values, newErrors, 'typeOfLoan', 'the type of loan');
  }

  if ((values.planingStr === CONST.Investment && values.dscrLoanBool !== CONST.Yes) || (values.homeTypeStr === CONST.MultiFamily && values.planingStr !== CONST.Investment)) {
    checkError(values, newErrors, 'estimatedRentalNum', 'the estimated rental income', null, true);
  }

  if (values.homeTypeStr === CONST.MultiFamily && values.planingStr === CONST.Investment) {
    checkError(values, newErrors, 'mixedUseBool', 'if the property is mixed use');
  }

  if (values.homeTypeStr === CONST.MultiFamily && !values.unitsStr) {
    checkError(values, newErrors, 'unitsStr', 'the number of units');
  }

  if (values.homeTypeStr === CONST.SingleFamily || values.homeTypeStr === CONST.NotSure) {
    checkError(values, newErrors, 'payHoaFeesStr', 'if you pay HOA fees');
  }

  return newErrors;
};

const validateProfilePurchase = (values) => {
  const newErrors = {};
  checkError(values, newErrors, 'hadLoanBeforeBool', 'if you have had a loan before');
  checkError(values, newErrors, 'hasSignedPurchaseBool', 'if you have a signed purchase agreement');
  checkError(values, newErrors, 'makeIncome', 'the employment type');
  console.info('newErrors', newErrors);
  checkError(values, newErrors, 'homeTypeStr', 'the property type');
  checkError(values, newErrors, 'planingStr', 'if you are planning on living in the property');

  checkError(values, newErrors, 'creditScoreNum', 'a credit score', null, true);

  if (values.hasSignedPurchaseBool === CONST.Yes) {
    checkError(values, newErrors, 'addressLine', 'an address from the auto complete list', 'addressStr');
    checkError(values, newErrors, 'zipCodeStr', 'an address from the auto complete list that has a zip code', 'addressStr');
  } else {
    checkError(values, newErrors, 'zipCodeStr', 'an address from the auto complete list that has a zip code', 'addressStr');
  }

  checkError(values, newErrors, 'cityStr', 'an address from the auto complete list', 'address');
  checkError(values, newErrors, 'stateStr', 'an address from the auto complete list', 'address');

  if (values.hasSignedPurchaseBool === CONST.Yes) {
    checkError(values, newErrors, 'purchaseAmountNum', 'the purchase amount', null, true);
  }

  if (values.hasSignedPurchaseBool !== CONST.Yes) {
    checkError(values, newErrors, 'howSoonToBuyStr', 'how soon until you will buy');
    checkError(values, newErrors, 'amountToSpendNum', 'the amount to spend', null, true);
  }

  checkError(values, newErrors, 'downPaymentNum', 'your down payment', null, true);

  if (values.planingStr === CONST.Investment) {
    checkError(values, newErrors, 'dscrLoanBool', 'if it is a DSCR loan');
  }

  if (values.planingStr === CONST.Investment && values.dscrLoanBool === CONST.Yes) {
    checkError(values, newErrors, 'registerUnderLlcBool', 'if you are buying in your name');
  }

  if (values.dscrLoanBool !== CONST.Yes) {
    checkError(values, newErrors, 'typeOfLoan', 'the type of loan');
  }

  if ((values.planingStr === CONST.Investment && values.dscrLoanBool !== CONST.Yes) || (values.homeTypeStr === CONST.MultiFamily && values.planingStr !== CONST.Investment)) {
    checkError(values, newErrors, 'estimatedRentalNum', 'the estimated rental income', null, true);
  }

  if (values.homeTypeStr === CONST.MultiFamily && values.planingStr === CONST.Investment) {
    checkError(values, newErrors, 'mixedUseBool', 'if the property is mixed use');
  }

  if (values.homeTypeStr === CONST.MultiFamily && !values.unitsStr) {
    checkError(values, newErrors, 'unitsStr', 'the number of units');
  }

  if (values.hasSignedPurchaseBool === CONST.Yes && (values.homeTypeStr === CONST.SingleFamily || values.homeTypeStr === CONST.NotSure)) {
    checkError(values, newErrors, 'payHoaFeesStr', 'if you pay HOA fees');
  }

  if (values.payHoaFeesStr === CONST.Yes) {
    checkError(values, newErrors, 'hoaFeesAmountNum', 'how much you pay monthly in HOA fees', null, true);
  }

  return newErrors;
};

const noop = () => {};

//#region Mapping from Hexagon format (stored in Kustomer) to calculator format
const mapUsage = (usage) => {
  let value = '';

  switch ((usage || '').toLowerCase()) {
    case 'investment':
      value = 2;
      break;
    case 'primary':
      value = 0;
      break;
    case 'second home':
      value = 1;
      break;
    default:
      break;
  }

  return value;
};

const mapPropertyType = (propertyType) => {
  let value;

  switch ((propertyType || '').toLowerCase()) {
    case 'condo':
      value = 2;
      break;
    case 'multifamily':
      value = 3;
      break;
    case 'singlefamily':
      value = 0;
      break;
    case 'townhouse':
      value = 2;
      break; // was 5, changed to condo 2022-05-18
    case 'notsure':
      value = -1;
      break;
    case 'plannedunitdevelopment':
      value = -1;
      break;
    default:
      break;
  }

  return value;
};

const mapCreditRange = (credit) => {
  let value = credit || 0;

  if (value >= 760) {
    return 760;
  }
  if (value >= 740 && value <= 759) {
    return 740;
  }
  if (value >= 720 && value <= 739) {
    return 720;
  }
  if (value >= 700 && value <= 719) {
    return 700;
  }
  if (value >= 680 && value <= 699) {
    return 680;
  }
  if (value >= 660 && value <= 679) {
    return 660;
  }
  if (value >= 620 && value <= 659) {
    return 620;
  }
  if (value >= 580 && value <= 619) {
    return 580;
  }
  if (value < 580) {
    return 570;
  }
};
//#endregion

const handleAddressSelected = (address, valuesSetter) => {
  let cityStr = getAddressDetails(address.address_components, 'locality');
  if (!cityStr) cityStr = getAddressDetails(address.address_components, 'neighborhood');
  if (!cityStr) cityStr = getAddressDetails(address.address_components, 'administrative_area_level_2');

  const zipCodeStr = getAddressDetails(address.address_components, 'postal_code');
  // const streetNumber = getAddressDetails(address.address_components, 'street_number');
  const stateStr = getAddressDetails(address.address_components, 'administrative_area_level_1', 'short_name');

  const updatedValue = {
    addressLine: `${getAddressDetails(address.address_components, 'street_number') ?? ''} ${getAddressDetails(address.address_components, 'route') ?? ''}`.trim(),
    cityStr
  };

  // console.info('address', JSON.stringify(address))
  // console.info('updatedValue', JSON.stringify(updatedValue))
  if (zipCodeStr) updatedValue.zipCodeStr = zipCodeStr;
  if (zipCodeStr) updatedValue.zipCodeStr = zipCodeStr;
  if (stateStr) updatedValue.stateStr = stateStr;

  valuesSetter((prev) => ({ ...prev, ...updatedValue }));
};

const pushDL = (event, data) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event,
    data: {
      ...(window.dataLayer.data || {}),
      ...data
    }
  });
};

const getYesNo = (boolVal) => (boolVal ? CONST.Yes : CONST.No);

const transformProfile = (profile) => {
  profile.hadLoanBeforeBool = getYesNo(profile.hadLoanBeforeBool);
  profile.hasSignedPurchaseBool = getYesNo(profile.hasSignedPurchaseBool);
  profile.mixedUseBool = getYesNo(profile.mixedUseBool);
  profile.registerUnderLlcBool = getYesNo(profile.registerUnderLlcBool);
  profile.dscrLoanBool = getYesNo(profile.dscrLoanBool);
};

const getUnitsValue = (units) => {
  const u = Number.parseInt(units) ? Number.parseInt(units) : undefined;
  if (u) {
    if (u > 5) return '5+';
    else return units;
  }

  return undefined;
};

export {
  getUnitsValue,
  checkError,
  checkWarning,
  toCurrency,
  getAddressDetails,
  noop,
  validateProfilePurchase,
  validateProfileRefi,
  mapUsage,
  mapCreditRange,
  mapPropertyType,
  handleAddressSelected,
  pushDL,
  transformProfile
};
