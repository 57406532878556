import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from '@beelineloans/cx-library/dist/components/buttons';
import { H3 } from '@beelineloans/cx-library/dist/components/typography';
import Modal from '@beelineloans/cx-library/dist/components/modals/Modal';
import { ErrorMessage } from '@beelineloans/cx-library/dist/components/typography';
import LogoRed from '@beelineloans/cx-library/dist/images/logos/beeline-logo';
import ValidationFields from './ValidationFields';

const Logo = styled.img`
  height: 28px;
  margin-bottom: 34px;

	@media only screen and (${props => props.theme.mediaBreakpoints.desktop}) {
    height: 48px;
    margin-bottom: 54px;
  }
`;

const Divider = styled.hr`
  width: calc(100% + 46px);
  height: 1px;
  border: 0;
  background: ${props => props.theme.colours.border.light};
  margin: 41px 0 22px -23px;;
  
	@media only screen and (${props => props.theme.mediaBreakpoints.desktop}) {
    width: calc(100% + 170px);
    margin-left: -85px;
  }
`;

const ButtonWrap = styled.div`
  text-align: center
`;

const ButtonLarge = styled(Button)`
  width: 100%;
  
	@media only screen and (${props => props.theme.mediaBreakpoints.tablet}) {
    width: 210px;
  }
`;

const ValidationModal = ({ children, fields, open, onContinue, profile, validationFunction, loading, error }) => {
  const [workingValues, setWorkingValues] = useState(profile);
  const [errors, setErrors] = useState({});

  const continueClick = () => {
    const updates = { ...profile, ...workingValues };
    const errors = validationFunction(updates, profile.isRefi)

    if (Object.entries(errors).length > 0) {
      setErrors(errors)
    } else {
      setErrors({});
      onContinue(updates);
    }
  }

  return (
    <Modal open={open} hideCloseButton>
      <Logo src={LogoRed} alt="Beeline Loans" />
      <H3>Good news. You’ve been matched with Beeline. We just need a couple of things to finalize your quote.</H3>
      <Divider />
      {children}
      <ValidationFields fields={{ ...fields, ...errors }} workingValues={workingValues} errors={errors} setter={setWorkingValues} />
      <ButtonWrap>
        <ButtonLarge onClick={continueClick} loading={loading}>Continue</ButtonLarge>
      </ButtonWrap>
      {error && (<ErrorMessage>{error}</ErrorMessage>)}
    </Modal>
  );
};

ValidationModal.propTypes = {
  fields: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onContinue: PropTypes.func.isRequired,
  validationFunction: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  children: PropTypes.any,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string
};

ValidationModal.defaultProps = {
  error: null
};

export default ValidationModal;