import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { Template } from "@beelineloans/cx-library/dist/components/layout/Template";
import { NavOnlyHeader } from "@beelineloans/cx-library/dist/components/layout/headers";
import ContinueForm from "./components/external/ContinueForm";
import HeresHow from "./components/external/HeresHow.js";
import BestPart from "./components/external/BestPart.js";
import LeadForm from "./components/internal/LeadForm";
import ScrollToTop from "./components/ScrollToTop";
import { noop } from "./helpers";
import { URLS } from "./const";
import {
  StateProvider,
  useSessionState,
} from "./components/external/state/StateProvider";
import Wrapper from "./components/external/Wrapper";

const BasicTemplate = ({ ...rest }) => {
  return (
    <Template
      {...rest}
      hideSignIn
      headerComponent={() => (
        <NavOnlyHeader navigation={null} onNavClick={noop} />
      )}
    />
  );
};

const EmptyTemplate = ({ ...rest }) => {
  return <Template {...rest} hideSignIn headerComponent={null} />;
};

const LeadRoutes = () => {
  return (
    <BasicTemplate>
      <Switch>
        <Route exact path={`${URLS.LEAD}/:crmId/:profileId`}>
          <LeadForm />
        </Route>
        <Route exact path={`${URLS.LEAD}/:crmId`}>
          <LeadForm />
        </Route>
      </Switch>
    </BasicTemplate>
  );
};

const ContinueRoutes = () => {
  const [{ profile }] = useSessionState();

  return (
    <EmptyTemplate>
      <Switch>
        <Route exact path={`${URLS.CONTINUE}/ext/:externalId`}>
          <BasicTemplate>
            <ContinueForm />
          </BasicTemplate>
        </Route>
        <Route exact path={URLS.HERES_HOW}>
          <Wrapper>
            <HeresHow />
          </Wrapper>
        </Route>
        <Route exact path={URLS.BEST_PART}>
          <Wrapper>
            <BestPart />
          </Wrapper>
        </Route>
        <Route exact path={`${URLS.CONTINUE}/:crmId/:profileId`}>
          <BasicTemplate>
            <ContinueForm />
          </BasicTemplate>
        </Route>
        <Route exact path={`${URLS.CONTINUE}/:profileId`}>
          <BasicTemplate>
            <ContinueForm />
          </BasicTemplate>
        </Route>
        <Route path={URLS.CONTINUE}>
          <Redirect to={`${URLS.CONTINUE}/${profile.profileId}`} />
        </Route>
        <Route path="/">
          <Redirect to={URLS.CONTINUE} />
        </Route>
      </Switch>
    </EmptyTemplate>
  );
};

const App = () => {
  return (
    <StateProvider>
      <Router>
        <ScrollToTop>
          <Switch>
            <LeadRoutes path={URLS.LEAD} />
            <ContinueRoutes path={URLS.CONTINUE} />
          </Switch>
        </ScrollToTop>
      </Router>
    </StateProvider>
  );
};

export default App;
