import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import SEO from '@beelineloans/cx-library/dist/components/SEO';
import Container from '@beelineloans/cx-library/dist/components/layout/Container';
import LoadingImage from '@beelineloans/cx-library/dist/components/LoadingImage';
import { getTry, postTry } from '@beelineloans/cx-library/dist/utils/fetch';
import { applyEndpoint } from '@beelineloans/cx-library/dist/utils/helpers';
import { validateProfilePurchase, validateProfileRefi, pushDL, transformProfile } from '../../helpers';
import { URLS, ACTIONS } from '../../const';
import ContinueProfile from './ContinueProfile';
import ValidationModal from './ValidationModal';
import { useSessionState } from './state/StateProvider';

const Wrap = styled(Container)`
  .container-inner {
    padding-top: 0px;
    padding-bottom: 100px;
  }
`;

const CheckValidationErrors = (values, isRefi, isLoading = false) => {
  const newErrors = isRefi ? validateProfileRefi(values, isLoading) : validateProfilePurchase(values);
  console.info('newErrors', JSON.stringify(newErrors))
  return newErrors;
}

const updateState = (profile, borrower, loading, dispatch) => {
  dispatch({
    type: ACTIONS.ALL,
    profile,
    borrower,
    loading
  });
}

const ContinueForm = () => {
  let { profileId, externalId } = useParams();
  const [{ profile, borrower, loading }, dispatch] = useSessionState();
  const [validationErrors, setValidationErrors] = useState({});
  const [saving, setSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [initComplete, setInitComplete] = useState(false);
  // /lead/ext/:externalId

  useEffect(async () => {
    let crmId;
    if (profileId || externalId) {
      if (profile.profileId && profile.profileId === profileId) {
        dispatch({ type: ACTIONS.LOADING, data: false });
        setValidationErrors(CheckValidationErrors(profile, profile.isRefi, true));
        setInitComplete(true);
      } else {
        updateState({}, {}, true, dispatch); // empty previous session as its a different profile
        const endpoint = externalId
          ? `${process.env.REACT_APP_API_ENDPOINT}ext/${externalId}`
          : `${process.env.REACT_APP_API_ENDPOINT}${profileId}`;

        const borrowerProfile = await getTry(endpoint)

        if (borrowerProfile.status === 'SUCCESS') {
          const profileData = {
            profileId: borrowerProfile.profileId,
            address: `${borrowerProfile.profile.addressStr}, ${borrowerProfile.profile.cityStr} ${borrowerProfile.profile.stateStr}, ${borrowerProfile.profile.zipCodeStr}`,
            isRefi: (borrowerProfile.profile.buyingOrRefinanceStr || '').toLowerCase().indexOf('refi') > -1,
            ...borrowerProfile.profile
          };

          crmId = borrowerProfile.borrower.id;
          transformProfile(profileData)
          // const tempProfileData = { ...profileData, homeType: null };
          updateState(profileData, { id: borrowerProfile.borrower.id, ...borrowerProfile.borrower }, false, dispatch);

          setValidationErrors(CheckValidationErrors(profileData, profileData.isRefi, true));
        }

        setInitComplete(true);
      }

      dispatch({ type: ACTIONS.LOADING, data: false });
    } else {
      updateState({}, {}, dispatch);
      dispatch({ type: ACTIONS.LOADING, data: false });
    }

    pushDL('init', { id: crmId, profileId: profileId, externalId });

  }, []);

  const updateProfile = async (updatedValues) => {
    if (saving) return;

    setSaving(true);
    setErrorMessage(null);

    console.info('data', { ...updatedValues, profileId: profile.profileId })
    dispatch({
      type: ACTIONS.STORE_PROFILE,
      data: { ...updatedValues, profileId: profile.profileId }
    });
    let hasErrored = false;

    const saveResponse = await postTry(`${process.env.REACT_APP_API_ENDPOINT}submit/`, {
      ...updatedValues,
      crmId: borrower.id,
      profileId: profile.profileId,
    });

    if (!saveResponse || !saveResponse.id) hasErrored = true;

    if (hasErrored) setErrorMessage('Oops something went wrong.');

    setSaving(false);
    setValidationErrors({});
  }

  if (!loading && !profile.profileId && initComplete) {
    console.info('redir', loading, profile, initComplete)
    window.location = applyEndpoint;
  }

  return (
    <>
      <SEO
        path={URLS.CONTINUE}
        title={`Your Beeline loan quote`}
      />
      {loading && (<Wrap><LoadingImage /></Wrap>)}
      {!loading && profile.profileId && (
        <>
          <ValidationModal
            open={Object.entries(validationErrors).length > 0 || errorMessage !== null}
            fields={validationErrors}
            onContinue={updateProfile}
            validationFunction={CheckValidationErrors}
            profile={profile}
            loading={saving}
            error={errorMessage}
          />
          <ContinueProfile
            borrower={borrower}
            profile={profile}
            fieldsCompleted={Object.entries(validationErrors).length === 0 && initComplete}
          />
        </>
      )}
    </>
  );
};

export default ContinueForm;
