import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  NumberField,
  RadioFieldV2,
  DropdownField,
} from "@beelineloans/cx-library/dist/components/forms/fields";
import AddressLookup from "../AddressLookup";
import {
  CONST,
  creditRangeOptions,
  refiWhyOptions,
  cashOutReasons,
  hadLoanOptions,
  PSOptions,
  homeTypeOptionsPurchase,
  homeTypeOptionsRefi,
  planningOptions,
  unitOptions,
  moreThan1UnitOptions,
  howSoonOptions,
  payHoaFeesOptions,
  dscrLoanOptions,
  registerUnderLLCOptions,
  typeOfLoanOptions,
  typeOfLoanSelfEmployedOption,
  typeOfLoanNotSureOption,
  makeIncomeOptions,
} from "../../const";
import { handleAddressSelected, getUnitsValue } from "../../helpers";

const Radio = styled(RadioFieldV2)`
  input {
    margin: 0 5px 5px 0;
  }
`;

const FieldWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

const ValidationFields = ({ fields, workingValues, errors, setter }) => {
  const keyArray = Object.keys(fields);
  if (!fields && !keyArray) return;

  const hasAddressFields =
    keyArray.includes("address") || keyArray.includes("addressLine"); // || keyArray.includes('state') || keyArray.includes('zipCode') || keyArray.includes('city');
  // const showFullAddress = (workingValues.hasSignedPurchaseBool && workingValues.hasSignedPurchaseBool === 'Yes') || workingValues.isRefi;

  const handleInputChange = (field, type) => (event) => {
    let newValue = event.target.value;

    if (typeof newValue === "undefined") newValue = event; // used for DropDownCustom component

    if (type === "num" && newValue !== null) {
      const parsedNumber = Number.parseFloat(newValue);
      if (!isNaN(parsedNumber)) newValue = parsedNumber;
      else newValue = undefined;
    }

    const updatedValue = {
      [field]: newValue,
    };

    if (field === "addressLine") {
      updatedValue[
        "address"
      ] = `${newValue}, ${workingValues.city} ${workingValues.state}, ${workingValues.zipCode}`;
    }

    setter((prev) => ({ ...prev, ...updatedValue }));
  };

  const addressSelected = (address) => {
    handleAddressSelected(address, setter);
  };

  const productTypeOptions = [...typeOfLoanOptions];
  if (workingValues.makeIncome === "self-employed") {
    productTypeOptions.push(typeOfLoanSelfEmployedOption);
    productTypeOptions.push(typeOfLoanNotSureOption);
  }

  return (
    <FieldWrap>
      {hasAddressFields && (
        <AddressLookup
          address={workingValues.addressLine}
          placeType={
            workingValues.isRefi ||
            workingValues.hasSignedPurchaseBool === CONST.Yes
              ? "address"
              : "(regions)"
          }
          onChange={handleInputChange("addressLine")}
          onSelect={addressSelected}
          error={errors.address}
          label={
            workingValues.isRefi
              ? "What’s the address of the property you want to refinance?"
              : workingValues.hasSignedPurchaseBool === CONST.Yes
              ? "What’s the address of the property you want to buy?"
              : "Nice. What area are you looking to buy?"
          }
        />
      )}
      {keyArray.map((field) => {
        if (field === "zipCodeStr") {
          return (
            <NumberField
              key="zipCodeStr"
              id="zipCodeStr"
              label="Zip Code"
              value={workingValues.zipCodeStr}
              onInputChange={handleInputChange("zipCodeStr")}
              border={false}
              rounded
              placeholder="00000"
              error={errors.zipCodeStr}
            />
          );
        } else if (field === "makeIncome") {
          return (
            <Radio
              id="makeIncome"
              label="What’s your employment type?"
              onInputChange={handleInputChange("makeIncome")}
              options={makeIncomeOptions}
              selectedValue={workingValues.makeIncome}
              border={false}
              error={errors.makeIncome}
            />
          );
        } else if (field === "maktypeOfLoaneIncome") {
          return (
            <Radio
              id="typeOfLoan"
              label="What type of loan are you looking for?"
              options={productTypeOptions}
              onInputChange={handleInputChange("typeOfLoan")}
              selectedValue={workingValues.typeOfLoan}
              border={false}
              error={errors.typeOfLoan}
            />
          );
        } else if (field === "whyRefiStr") {
          return (
            <Radio
              key="whyRefiStr"
              id="whyRefiStr"
              label="So, tell us why you want to refinance."
              onInputChange={handleInputChange("whyRefiStr")}
              options={refiWhyOptions}
              selectedValue={workingValues.whyRefiStr}
              border={false}
              error={errors.whyRefiStr}
            />
          );
        } else if (field === "remainingAmountNum") {
          return (
            <NumberField
              key="remainingAmountNum"
              id="remainingAmountNum"
              label="How much is left on your home loan?"
              value={workingValues.remainingAmountNum || 0}
              onInputChange={handleInputChange("remainingAmountNum", "num")}
              border={false}
              rounded
              prefix="$"
              formatValue
              placeholder="0"
              error={errors.remainingAmountNum}
            />
          );
        } else if (field === "currentWorthNum") {
          return (
            <NumberField
              key="currentWorthNum"
              id="currentWorthNum"
              label="What’s your home currently worth? Feel free to guesstimate."
              value={workingValues.currentWorthNum || 0}
              onInputChange={handleInputChange("currentWorthNum", "num")}
              border={false}
              rounded
              prefix="$"
              formatValue
              placeholder="0"
              error={errors.currentWorthNum}
            />
          );
        } else if (field === "homeTypeStr") {
          return (
            <Radio
              key="homeTypeStr"
              id="homeTypeStr"
              label="What type of property is it?"
              onInputChange={handleInputChange("homeTypeStr")}
              options={
                workingValues.isRefi
                  ? homeTypeOptionsRefi
                  : homeTypeOptionsPurchase
              }
              selectedValue={workingValues.homeTypeStr}
              border={false}
              error={errors.homeTypeStr}
            />
          );
        } else if (field === "payHoaFeesStr") {
          return (
            <Radio
              key="payHoaFeesStr"
              id="payHoaFeesStr"
              label="Do you pay home owners association (HOA) fees or neighborhood dues for this property?"
              onInputChange={handleInputChange("payHoaFeesStr")}
              options={payHoaFeesOptions}
              selectedValue={workingValues.payHoaFeesStr}
              border={false}
              error={errors.payHoaFeesStr}
            />
          );
        } else if (field === "hoaFeesAmountNum") {
          return (
            <NumberField
              key="hoaFeesAmountNum"
              id="hoaFeesAmountNum"
              label="Okay, how much will you pay in HOA fees each month?"
              value={workingValues.hoaFeesAmountNum || 0}
              onInputChange={handleInputChange("hoaFeesAmountNum", "num")}
              border={false}
              rounded
              prefix="$"
              formatValue
              placeholder="0"
              error={errors.hoaFeesAmountNum}
            />
          );
        } else if (field === "planingStr") {
          return (
            <Radio
              key="planingStr"
              id="planingStr"
              label="And are you planning to live in this home?"
              options={planningOptions}
              onInputChange={handleInputChange("planingStr")}
              selectedValue={workingValues.planingStr}
              border={false}
              error={errors.planingStr}
            />
          );
        } else if (field === "takeOutAmountNum") {
          return (
            <NumberField
              key="takeOutAmountNum"
              id="takeOutAmountNum"
              label="How much do you want to take out?"
              value={workingValues.takeOutAmountNum || 0}
              onInputChange={handleInputChange("takeOutAmountNum", "num")}
              border={false}
              rounded
              prefix="$"
              formatValue
              placeholder="0"
              error={errors.takeOutAmountNum}
            />
          );
        } else if (field === "cashOutReasonStr") {
          return (
            <Radio
              key="cashOutReasonStr"
              id="cashOutReasonStr"
              label="We see you're requesting to get cash out with your refinance. What do you plan to use it for?"
              options={cashOutReasons}
              onInputChange={handleInputChange("cashOutReasonStr")}
              selectedValue={workingValues.cashOutReasonStr}
              border={false}
              error={errors.cashOutReasonStr}
            />
          );
        } else if (field === "unitsStr") {
          return (
            <Radio
              key="unitsStr"
              id="unitsStr"
              label="How many units is this property?"
              options={
                workingValues.homeTypeStr === CONST.MultiFamily
                  ? moreThan1UnitOptions
                  : unitOptions
              }
              onInputChange={handleInputChange("unitsStr")}
              selectedValue={getUnitsValue(workingValues.unitsStr)}
              border={false}
              error={errors.unitsStr}
            />
          );
        } else if (field === "hadLoanBeforeBool") {
          return (
            <Radio
              key="hadLoanBeforeBool"
              id="hadLoanBeforeBool"
              label="Have you had a home loan before?"
              onInputChange={handleInputChange("hadLoanBeforeBool")}
              options={hadLoanOptions}
              selectedValue={workingValues.hadLoanBeforeBool}
              border={false}
              error={errors.hadLoanBeforeBool}
            />
          );
        } else if (field === "hasSignedPurchaseBool") {
          return (
            <Radio
              key="hasSignedPurchaseBool"
              id="hasSignedPurchaseBool"
              label="Okay, and do you have a signed purchase and sale agreement?"
              onInputChange={handleInputChange("hasSignedPurchaseBool")}
              options={PSOptions}
              selectedValue={workingValues.hasSignedPurchaseBool}
              border={false}
              error={errors.hasSignedPurchaseBool}
            />
          );
        } else if (field === "creditScoreNum") {
          return (
            <DropdownField
              key="creditScoreNum"
              id="creditScoreNum"
              label="Ok, what is your credit score?"
              options={creditRangeOptions}
              onInputChange={handleInputChange("creditScoreNum", "num")}
              selectedValue={workingValues.creditScoreNum}
              border={false}
              rounded
              error={errors.creditScoreNum}
            />
          );
        } else if (field === "purchaseAmountNum") {
          return (
            <NumberField
              key="purchaseAmountNum"
              id="purchaseAmountNum"
              label="Sweet! What's the agreed purchase price?"
              value={workingValues.purchaseAmountNum || 0}
              onInputChange={handleInputChange("purchaseAmountNum", "num")}
              border={false}
              rounded
              prefix="$"
              formatValue
              placeholder="0"
              error={errors.purchaseAmountNum}
            />
          );
        } else if (field === "howSoonToBuyStr") {
          return (
            <Radio
              key="howSoonToBuyStr"
              id="howSoonToBuyStr"
              label="How soon are you planning to buy?"
              onInputChange={handleInputChange("howSoonToBuyStr")}
              options={howSoonOptions}
              selectedValue={workingValues.howSoonToBuyStr}
              border={false}
              error={errors.howSoonToBuyStr}
            />
          );
        } else if (field === "amountToSpendNum") {
          return (
            <NumberField
              key="amountToSpendNum"
              id="amountToSpendNum"
              label="Roughly, what are you thinking price-wise?"
              value={workingValues.amountToSpendNum || 0}
              onInputChange={handleInputChange("amountToSpendNum", "num")}
              border={false}
              rounded
              prefix="$"
              formatValue
              placeholder="0"
              error={errors.amountToSpendNum}
            />
          );
        } else if (field === "downPaymentNum") {
          return (
            <NumberField
              key="downPaymentNum"
              id="downPaymentNum"
              label="How much is your down payment?"
              value={workingValues.downPaymentNum || 0}
              onInputChange={handleInputChange("downPaymentNum", "num")}
              border={false}
              rounded
              prefix="$"
              formatValue
              placeholder="0"
              error={errors.downPaymentNum}
            />
          );
        } else if (field === "estimatedRentalNum") {
          return (
            <NumberField
              key="estimatedRentalNum"
              id="estimatedRentalNum"
              label={
                workingValues.homeType === CONST.MultiFamily
                  ? "So, how much rent do you get each month for the other units in your multi-family property?"
                  : "How much rent are you expecting to get each month?"
              }
              value={workingValues.estimatedRentalNum || 0}
              onInputChange={handleInputChange("estimatedRentalNum", "num")}
              border={false}
              rounded
              prefix="$"
              formatValue
              placeholder="0"
              error={errors.estimatedRentalNum}
            />
          );
        } else if (field === "dscrLoanBool") {
          return (
            <Radio
              key="dscrLoanBool"
              id="dscrLoanBool"
              label="Are you applying using the rental property’s income (DSCR loan) or your personal income?"
              onInputChange={handleInputChange("dscrLoanBool")}
              options={dscrLoanOptions}
              selectedValue={workingValues.dscrLoanBool}
              border={false}
              error={errors.dscrLoanBool}
            />
          );
        } else if (field === "registerUnderLlcBool") {
          return (
            <Radio
              key="registerUnderLlcBool"
              id="registerUnderLlcBool"
              label="Are you buying in your name or in business entity?"
              onInputChange={handleInputChange("registerUnderLlcBool")}
              options={registerUnderLLCOptions}
              selectedValue={workingValues.registerUnderLlcBool}
              border={false}
              error={errors.registerUnderLlcBool}
            />
          );
        } else {
          return null;
        }
      })}
    </FieldWrap>
  );
};

ValidationFields.propTypes = {
  fields: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  workingValues: PropTypes.object.isRequired,
  setter: PropTypes.func.isRequired,
};

export default ValidationFields;
