import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import SEO from "@beelineloans/cx-library/dist/components/SEO";
import {
  H2,
  Paragraph,
} from "@beelineloans/cx-library/dist/components/typography";
import Container from "@beelineloans/cx-library/dist/components/layout/Container";
import LoadingImage from "@beelineloans/cx-library/dist/components/LoadingImage";
import { getTry } from "@beelineloans/cx-library/dist/utils/fetch";
import WarmUp from "./WarmUp";
import { pushDL, transformProfile } from "../../helpers";

const Wrap = styled(Container)`
  .container-inner {
    padding-top: 0px;
    padding-bottom: 100px;
  }
`;

const User = ({ name }) => <b>{name}</b>;

const LeadForm = () => {
  let { crmId, profileId } = useParams();
  const [loading, setLoading] = useState(true);
  const [defaults, setDefaults] = useState();
  const [name, setName] = useState();
  const [useCrmId, setUseCrmId] = useState(crmId);

  console.info("crmId", crmId);
  console.info("profileId", profileId);

  useEffect(async () => {
    if (
      window &&
      window.document &&
      window.document.referrer &&
      window.document.referrer.indexOf("https://demo.beeline.relcu.org/") >
        -1 &&
      window.document.location.href.indexOf("makeabeeline.com") > -1
    ) {
      window.location.replace(
        window.document.location.href.replace(
          "makeabeeline.com",
          "makeabeeline.dev"
        )
      );
    }

    let assignToEmail;
    let profileData;

    if (window) {
      const urlParams = new URLSearchParams(window.location.search);
      assignToEmail = urlParams.get("email");
    }
    console.info("assignToEmail", assignToEmail);

    if (profileId) {
      const borrowerProfile = await getTry(
        `${process.env.REACT_APP_API_ENDPOINT}${profileId}`
      );
      console.info("borrowerProfile", JSON.stringify(borrowerProfile));
      if (borrowerProfile.status === "SUCCESS") {
        setUseCrmId(borrowerProfile.borrower.id);

        profileData = {
          addressStr: `${borrowerProfile.profile.addressStr}, ${borrowerProfile.profile.cityStr} ${borrowerProfile.profile.stateStr}, ${borrowerProfile.profile.zipCodeStr}`,
          ...borrowerProfile.profile,
        };
        setName(
          `${borrowerProfile.borrower.firstName} ${borrowerProfile.borrower.lastName}`
        );
        transformProfile(profileData);
      }
    } else {
      profileData = {
        assignToEmail: assignToEmail,
      };
    }

    setDefaults(profileData);
    setLoading(false);

    pushDL("init", { id: useCrmId });
  }, [profileId]);

  return (
    <>
      <SEO
        path="/lead/"
        title={`Lead Form | ${process.env.REACT_APP_DEFAULT_TITLE}`}
      />
      <Wrap>
        {loading && <LoadingImage />}
        {!loading && (
          <>
            <H2>
              Warm up questions{" "}
              {name ? (
                <>
                  for <User name={name} />
                </>
              ) : null}
            </H2>
            <Paragraph>
              Only use this page for NEW customers (no login).
              <br />
              To create loans for returning customers.
              <br />
              Please log into the admin portal via the "Login as customer" link
              in Relcu and use the Copy to New feature.
            </Paragraph>
            <WarmUp
              crmId={useCrmId}
              profileId={profileId}
              defaultValues={defaults}
            />
          </>
        )}
      </Wrap>
    </>
  );
};

export default LeadForm;
