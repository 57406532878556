import React, { useEffect } from 'react';
import { H2, Paragraph } from '@beelineloans/cx-library/dist/components/typography';
import SEO from '@beelineloans/cx-library/dist/components/SEO';
import { URLS, ACTIONS } from '../../const';
import { useSessionState } from './state/StateProvider';
import Banner from '../../images/banner.svg';

const HeresHow = () => {
  const [, dispatch] = useSessionState();

  useEffect(() => {
    dispatch({
      type: ACTIONS.PAGE,
      data: {
        image: Banner,
        nextUrl: URLS.BEST_PART,
        nextText: "Sounds good..."
      }
    });
  }, []);

  return (  
    <>
      <SEO
        path={URLS.HERES_HOW}
        title="Here's how we'll do it!"
      />
      <H2>Here's how we'll do it!</H2>
      <Paragraph>
        You’ll create your Beeline profile by adding a few key pieces of financial info, so we can give you a real rate that you can lock.
      </Paragraph>
    </>
  );
};

HeresHow.propTypes = {
};

HeresHow.defaultProps = {

};

export default HeresHow;