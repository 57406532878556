import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import { TextField } from '@beelineloans/cx-library/dist/components/forms/fields';
import { getAddressDetails } from '../helpers';

const LookupWrapper = styled.ul`
  list-style: none;
  position: relative;
  background: ${(props) => props.theme.colours.background.navy};
  z-index: 1;
  color: ${(props) => props.theme.colours.text.light};
  width: calc(100% - 60px);
  padding: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  top: -50px;
  margin-top: 0;
  margin-left: 30px;
`;

const LookupItem = styled.li`
  line-height: 26px;
  padding: 5px;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    background: ${(props) => props.theme.colours.background.navy};
    color: ${(props) => props.theme.colours.text.light};
  }
`;

const AddressLookup = ({ address, placeType, onChange, onSelect, label, error }) => {
  const [workingAddress, setWorkingAddress] = useState(address);

  const handleAddressChange = (value) => {
    console.info('value', value);
    setWorkingAddress(value);
    onChange({
      target: {
        value
      }
    });
  };

  const handleSelect = async (add) => {
    await geocodeByAddress(add)
      .then((results) => {
        // street_number
        let streetNumber = getAddressDetails(results[0].address_components, 'street_number');
        const addressResult = results[0];
        if (!streetNumber) {
          streetNumber = workingAddress.indexOf(' ') > -1 ? workingAddress.split(' ')[0] : '';
          addressResult.address_components.push({
            long_name: streetNumber,
            short_name: streetNumber,
            types: ['street_number']
          });
          addressResult.formatted_address = `${streetNumber} ${addressResult.formatted_address}`;
        }

        setWorkingAddress(addressResult.formatted_address);
        onSelect(addressResult, addressResult.formatted_address);
      })
      .catch((e) => {
        console.info('e', e);
      });
  };

  return (
    <>
      <PlacesAutocomplete
        value={workingAddress}
        onChange={handleAddressChange}
        onSelect={handleSelect}
        shouldFetchSuggestions={(workingAddress || '').length >= 4}
        searchOptions={{
          types: [placeType],
          componentRestrictions: {
            country: ['us']
          }
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
          return (
            <>
              <TextField
                id="addressStr"
                value={workingAddress}
                label={label}
                className="addressLookup"
                ariaLabel={label}
                autocomplete="false"
                border={false}
                rounded
                error={error}
                {...getInputProps({})}
              ></TextField>
              <LookupWrapper>
                {loading && <LookupItem key={`loading_${loading}`}>Loading...</LookupItem>}
                {suggestions.map((suggestion) => (
                  <LookupItem key={suggestion.place_id} {...getSuggestionItemProps(suggestion)}>
                    {suggestion.description}
                  </LookupItem>
                ))}
              </LookupWrapper>
            </>
          );
        }}
      </PlacesAutocomplete>
    </>
  );
};

AddressLookup.propTypes = {
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  address: PropTypes.string.isRequired,
  placeType: PropTypes.string,
  label: PropTypes.string.isRequired,
  error: PropTypes.string
};

AddressLookup.defaultProps = {
  onChange: () => {},
  onSelect: () => {},
  placeType: 'address',
  error: null
};

export default AddressLookup;
