import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, ButtonText } from '@beelineloans/cx-library/dist/components/buttons';
import Container from '@beelineloans/cx-library/dist/components/layout/Container';
import LogoNavy from '@beelineloans/cx-library/dist/images/logos/beeline-logo';
import LogoWhite from '@beelineloans/cx-library/dist/images/logos/beeline-logo-white';
import { useSessionState } from './state/StateProvider';
import { URLS } from '../../const';
import Spec from '../../images/spec.png';

const Wrap = styled.section`
  display: flex;
  flex-direction: column;
  height: 100vh;

	@media only screen and (${props => props.theme.mediaBreakpoints.desktop}) {
    flex-direction: row;
  }
`;

const ImageWrap = styled.div`
  width: 100%;
  position: relative;
  background-image: url(${props => props.$backImage}), url(${Spec});
  background-repeat: no-repeat, repeat;
  background-size: 101%, 20%;
  background-position: center 90%, left top;
  background-blend-mode: hard-light;
  height: 250px;
	
  @media only screen and (${props => props.theme.mediaBreakpoints.desktop}) {
    width: 50%;
    height: 100vh;
    background-repeat: no-repeat, repeat;
    background-size: 101%, 20%;
    background-position: center 90%, left top;
    background-blend-mode: hard-light;
  }
`;

const Content = styled(Container)`
  width: 100%;
  text-align: center;
  .container-inner {    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100vh - 250px);  
  }
  
  @media only screen and (${props => props.theme.mediaBreakpoints.desktop}) {
    width: 50%;
    .container-inner {  
      height: 100vh;  
    }
  }
`;

const ButtonWrap = styled.div`
  text-align: center;
  a {
    width: 100%;
  }
`;

const LogoLink = styled(ButtonText)`
  position: absolute;
  inset: 60px 28px;
`;

const Image = styled.picture`
  img {
    width: 94.5px;

    @media only screen and (${props => props.theme.mediaBreakpoints.desktop}) {
      width: 100px;
    }
  }
`;

const Wrapper = ({ children }) => {
  const [{ page, profile, borrower }] = useSessionState();  

  return (
    <Wrap>
      <ImageWrap $backImage={page.image}>
        <LogoLink to={`${URLS.CONTINUE}/${borrower.id}/${profile.profileId}`} linkComponent={Link}>
          <Image>
            <source srcset={LogoWhite} media="(min-width: 1280px)" />
            <img srcset={LogoNavy} alt="Beeline Loans" />
          </Image>
        </LogoLink> 
      </ImageWrap>
      <Content>
        <div>
          {children}
        </div>
        {page.nextUrl && page.nextText && (
          <ButtonWrap>
            <Button to={page.nextUrl} linkComponent={page.nextUrl.indexOf('http') > -1 ? undefined : Link}>{page.nextText}</Button>
          </ButtonWrap>
        )}
      </Content>
    </Wrap>
  );
};

Wrapper.propTypes = {
  children: PropTypes.any
};

Wrapper.defaultProps = {};

export default Wrapper;